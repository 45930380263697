<template>
    <v-toolbar :height="props.height" class="dynamicWidth" id="appMessageNotifications">
        <div class="notification-message-wrapper">
            <div class="notification-message" :style="{fontSize: props.font+'px'}"
                 >
                 <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
        </div>
    </v-toolbar>
</template>

<script setup>
    //PROPS
    const props = defineProps({
        'height': {
            type: Number,
            required: false,
            default: 40
        },
        'font': {
            type: Number,
            required: false,
            default: 14
        }
    });

    
    
    //IMPORTS
    import { reactive, computed, onMounted, onBeforeMount, defineProps } from 'vue'
    import { useAppStore } from '@/stores/app'



    //CONSTANTS
    const store = useAppStore()
    const state=reactive({
        minimized: true
    });

    
    
    //EVENTS
    onBeforeMount(() => {})
    onMounted(()=>{})
    
    
    
    //COMPUTED
    const currentMessage = computed(() => {
        let msg=null;
        if (store.notificationMessages.length){
            msg={
                type: store.notificationMessages[0].type,
            };
            if (state.minimized){
                msg.message=store.notificationMessages[0].message.replaceAll('<br>','. ');
            }else{
                msg.message=store.notificationMessages[0].message;
            }
        }
        return msg;
    })
    
    
    
    //METHODS
    const mouseinHandler=()=>{
        state.minimized=false;
        store.pauseNotificationMessages();
    }
    const mouseoutHandler=()=>{
        state.minimized=true;
        store.resumeNotificationMessages();
    }
    const clickHandler=()=>{
        store.nextNotificationMessage(true);
    }
</script>

