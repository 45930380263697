<template>
  <DialogNeo dialog-title="Matched Reports" :show="show" dialog-width="70%" dialog-height="50%">
    <template #content>
      <ag-grid-vue
        style="width: 100%; height: 100%;"
        class="ag-theme-alpine"
        :columnDefs="matchedReportsColumnDefs"
        :rowData="matchedReportsData"
        :defaultColDef="defaultColDef"
        :gridOptions="matchedReportsGridOptions"
        @grid-ready="onGridReady"
      ></ag-grid-vue>
      <ConfirmDialog
        :show="showConfirmDialog"
        message="Are you sure you want to match this report?"
        @confirm="() => { matchReportData(selectedReportId); showConfirmDialog = false; $emit('close') }"
        @cancel="showConfirmDialog = false"
      />
    </template>
    <template #dialog-header-right>
      <v-btn icon="mdi-close-thick" size="small" variant="text" @click="$emit('close')"></v-btn>
    </template>
  </DialogNeo>
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import { inject } from 'vue';
import DataLoading from '@/components/ui/DataLoading';

export default {
  components: {
    AgGridVue
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    selectedContainer: {
      type: [Object, null],
      required: true
    }
  },
  data() {
    return {
      matchedReportsData: [],
      matchedReportsColumnDefs: [
        { headerName: "ID", field: "id" },
        { headerName: "Unit Number", field: "unitNumber" },
        { headerName: "Depot", field: "depot" },
        { headerName: "Creation Date", field: "creationDate" },
        { headerName: "Service Type", field: "serviceType" },
        {
          headerName: '',
          field: 'matchReport',
          cellRenderer: params => {
            const icon = document.createElement('i');
            icon.classList.add('mdi-plus-thick', 'mdi', 'v-icon', 'notranslate', 'v-icon--size-small', 'v-theme--compassTheme', 'v-btn__icon');
            icon.setAttribute('aria-hidden', 'true');
            icon.setAttribute('title', 'Match Report'); 
            icon.addEventListener('click', () => {
              this.selectedReportId = params.data.id;
              this.showConfirmDialog = true;
            });
            return icon;
          },
          width: 60
        },
      ],
      matchedReportsGridOptions: {
        loadingOverlayComponent: DataLoading,
        loadingOverlayComponentParams: {
          type: 2
        },
      },
      defaultColDef: {
        sortable: true,
        filter: true,
      },
      loading: false,
      showConfirmDialog: false,   // New state for showing the confirmation dialog
      selectedReportId: null      // New state to track which report is being matched
    };
  },
  methods: {
    async fetchMatchedReportsData() {
      this.loading = true;
      try {
        const crunId = this.selectedContainer.crun_id;
        const depotSystem = this.selectedContainer.system;

        const response = await this.axios.get('/api/stockContainer/matchableReports', {
          params: { crunId, depotSystem }
        });

        this.matchedReportsData = response.data;
      } catch (error) {
        console.error('Failed to load matched reports data:', error);
      } finally {
        this.loading = false;
      }
    },
    async matchReportData(reportId) {
      this.loading = true;
      try {
        const crunId = this.selectedContainer.crun_id;
        const depotSystem = this.selectedContainer.system;

        let response = await this.axios.post('/api/stockContainer/matchReport', {
          crunId,
          depotSystem,
          reportId,
        });

        this.$emit('update:selectedContainer', { ...this.selectedContainer, reportId: response.reportId });

        this.fetchMatchedReportsData();
      } catch (error) {
        console.error('Failed to match report:', error);
      } finally {
        this.loading = false;
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      if (this.loading) {
        this.gridApi.showLoadingOverlay();
      } else {
        this.gridApi.hideOverlay();
      }
    }
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.fetchMatchedReportsData();
      }
    },
    loading(newVal) {
      if (newVal && this.gridApi) {
        this.gridApi.showLoadingOverlay();
      } else if (this.gridApi) {
        this.gridApi.hideOverlay();
      }
    }
  },
  created() {
    this.axios = inject('axios');
  },
  emits: ['close', 'update:selectedContainer'],
};
</script>

<style scoped>
.ag-theme-alpine {
  height: 100%;
  width: 100%;
}

.btn {
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #5a67d8;
}
</style>
